import { GoLinkExternal } from "react-icons/go";
import style from "./HomePage.module.css";
import logo from "./assets/28d77fccd23e3a575539bfdb89fd3cf1.png";
import { IoArrowForward } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
// import { MainNewFooter } from "../../Components/Footer/Footer";
import mixpanel from "mixpanel-browser";

// import for footer
import { useEffect, useState } from "react";
import { urlStrings } from "../../Utils/constant";

const FAQDetails = [
  {
    question: "What is anchors?",
    answer:
      "anchors is a multifunctional platform designed for content creators to monetize their work, host events, and engage in brand collaborations.",
  },
  {
    question: "Who can join anchors?",
    answer:
      "Content creators with over 5K followers, event organizers, solopreneurs, brands, and marketing agencies can benefit from joining anchors for different use-cases as part of their marketing & monetizing efforts.",
  },
  {
    question: "How can I sell digital products on anchors?",
    answer:
      "Creators can sell both free and paid digital products directly to their audience through the anchors platform - simple create a product, get the unique tracking link and share across.",
  },
  {
    question: "What features does anchors|Events offer?",
    answer:
      "The platform allows hosts to manage event registrations, accept payments, engage participants with gamified referral systems, and create marketing graphics.",
  },
  {
    question: "Can I use anchors|Collab to find paid collaborations?",
    answer:
      "Yes, this platform helps creators get discovered by brands and marketing agencies for paid collaborations and also allows creators to create professional online profiles.",
  },
  {
    question: "What marketing tools are available for event promotion?",
    answer:
      "The events platform offers creates autogenerated yet customized marketing graphics and attendee cards to share on social media. There is also an inbuilt referral system wherein each attendee gets rewards decided by you if they bring in more people.",
  },
  {
    question: "How do brands benefit from using anchors|Collab?",
    answer:
      "Brands can find and collaborate with relevant influencers to enhance their marketing efforts through targeted campaigns. No more doom-scrolling social media or never-ending negotiations.",
  },
  {
    question:
      "What analytics does anchors provide for creators and event hosts?",
    answer:
      "We offer detailed analytics to help creators and event hosts track engagement, sales, and participant demographics, aiding in better decision-making and strategy refinement.",
  },
  {
    question:
      "How can creators customize their profiles on anchors to attract more collaborations?",
    answer:
      "anchors urges creators to customize their profiles like a portfolio, with collab history, platform presence, detailed bios, etc. to enhance visibility and appeal to potential collaborators.",
  },
  {
    question:
      "Who should I contact for support with any of the anchors platforms?",
    answer: (
      <p>
        If you need assistance, each platform on anchors offers dedicated
        support to help users with their queries. Reply to any email sent to you
        or talk to us via{" "}
        <a
          href="https://cal.com/anchors-team/talk-to-us-anchors-collab"
          target="_blank"
          style={{ color: "#757575" }}
          onClick={() => mixpanel.track("clicked contact link: FAQs ")}
          rel="noreferrer"
        >
          here.
        </a>
      </p>
    ),
  },
];

export const HomePage = (props) => {
  const [activeFAQ, setActiveFAQ] = useState(null);
  const [topInfluencers, setTopInfluencers] = useState(null);

  useEffect(() => {
    const getTopInfluencers = async () => {
      const response = await fetch(
        "https://collab.anchors.in:5000/influencer/getFeaturedCreators",
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        }
      );
      const json = await response.json();
      setTopInfluencers(json.topInfluencer);
    };
    getTopInfluencers();
  }, []);

  const handleFAQClick = (e) => {
    const clickedItem = e.target.id;
    setActiveFAQ(activeFAQ === clickedItem ? null : clickedItem);

    let accordionItemHeader = document.getElementById(clickedItem);
    const accordionItemBody = accordionItemHeader.nextElementSibling;
    if (activeFAQ === clickedItem) {
      accordionItemBody.style.maxHeight = 0;
    } else {
      accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
    }
  };

  useEffect(() => {
    mixpanel.track("visited anchors landing page");
  }, []);

  return (
    <div className={style.main}>
      <NavBar />
      <section className={style.hero_container}>
        <div className={style.hero}>
          <div>
            <span className={style.title3}>For Creators</span>
            <div className={style.hero_title_lg}>
              <p className={style.title1}>Get Noticed!</p>
              <p className={style.title_gradient}>Let Brands Find You</p>
            </div>
            <p className={style.hero_description}>
              Connect with brands looking for your talent & expertise for paid
              collaborations
            </p>
          </div>
          <div className={style.hero_footer}>
            <button
              className={style.hero_button}
              onClick={() => {
                mixpanel.track("clicked Explore More button from hero section");
                window.open(
                  `${urlStrings.COLLAB_CLIENT}/influencer?ref=homepage`,
                  "_blank"
                );
              }}
            >
              <span>Explore More</span>
              <IoArrowForward size={24} />
            </button>
            <div className={style.joined_creator_holder}>
              <div class={style.avatars}>
                <span class={style.avatar}>
                  <img src={topInfluencers?.[0]?.profile} alt="" />
                </span>
                <span class={style.avatar}>
                  <img src={topInfluencers?.[1]?.profile} alt="" />
                </span>
                <span class={style.avatar}>
                  <img src={topInfluencers?.[2]?.profile} alt="" />
                </span>
              </div>
              <span style={{ fontSize: "14px" }}>1000+ creators joined</span>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------ */}
      <section className={style.section}>
        <div className={style.section_part_one} style={{ maxWidth: "445px" }}>
          <span className={style.title3}>For Creators</span>
          <p className={style.section_title}>Join AN exclusive community</p>
          <p className={style.section_description}>
            Where creators discuss best practices & hacks, brainstorm ideas,
            collaborate & grow together. Do you have {">"}10k followers? If so,
            come!
          </p>
          <button
            className={style.hero_button}
            style={{ padding: "12px 40px", marginTop: "52px" }}
            onClick={() => {
              mixpanel.track(
                "clicked Join Waitlist button from creators section"
              );
              window.open("/login/creators", "_blank");
            }}
          >
            <span>Join Waitlist</span>
            <IoArrowForward size={24} />
          </button>
        </div>
        <div className={style.section_part_two}>
          <img
            src="https://anchors-assets.s3.ap-south-1.amazonaws.com/Image+folder/community.svg"
            alt=""
          />
        </div>
      </section>

      {/* -------------------- */}
      <section className={style.section}>
        <div className={style.section_part_one} style={{ maxWidth: "445px" }}>
          <span className={style.title3}>For Coaches / Solopreneurs</span>
          <p className={style.section_title}>Event Management software</p>
          <p className={style.section_description}>
            Hosting an event is hard. Managing it is HARDER. Well, not anymore!
            From email & whatsapp reminders to marketing creatives, everything
            is 'done-for-you'
          </p>
          <button
            className={style.hero_button}
            style={{ padding: "12px 40px", marginTop: "52px" }}
            onClick={() => {
              mixpanel.track("clicked Explore How button from coaches section");
              window.open(`${urlStrings.EVENT_CLIENT}?ref=homepage`, "_blank");
            }}
          >
            <span>Explore How</span>
            <IoArrowForward size={24} />
          </button>
          <p className={style.no_card_req}>NO Credit Card Required</p>
        </div>
        <div className={style.section_part_two}>
          <img
            className={style.section_part_two_image}
            src="https://anchors-assets.s3.ap-south-1.amazonaws.com/anchors_Homepage_Event.png"
            alt=""
          />
        </div>
      </section>

      {/* -------------------- */}
      <section className={style.section}>
        <div className={style.section_part_one} style={{ maxWidth: "445px" }}>
          <span className={style.title3}>For brands / INDIVIDUALS</span>
          <p className={style.section_title}>
            Influencer marketing is the future
          </p>
          <p className={style.section_description}>
            Due to ad blockers & sponsored tags, reaching your customer is a
            challenge. Collaborating with influencers and content creators is
            the solution.
          </p>
          <button
            className={style.hero_button}
            style={{ padding: "12px 40px", marginTop: "52px" }}
            onClick={() => {
              mixpanel.track("clicked Check How button from brands section");
              window.open(
                `${urlStrings.COLLAB_CLIENT}/brand?ref=homepage`,
                "_blank"
              );
            }}
          >
            <span>Check How</span>
            <IoArrowForward size={24} />
          </button>
        </div>
        <div className={style.section_part_two}>
          <img
            src="https://anchors-assets.s3.ap-south-1.amazonaws.com/homepage_InfluencerMarketing.png"
            alt=""
          />
        </div>
      </section>

      <center>
        <div className={style.vector_hr}></div>
      </center>

      {/* --------FAQ-------------- */}
      <section className={style.section}>
        <div className={style.section_part_one} style={{ maxWidth: "348px" }}>
          <span className={style.title3}>FAQs</span>
          <p className={style.section_title}>Need a hand? We've got you.</p>
        </div>
        <div className={style.section_part_two}>
          <div className={style.faq_section} style={{ maxWidth: "754px" }}>
            {FAQDetails?.map((e, i) => {
              const faqId = `FAQ${i}`;
              return (
                <div className={style.faq_section_item} key={i}>
                  <div
                    onClick={handleFAQClick}
                    className={style.faq_section_item_header}
                    id={faqId}
                  >
                    {e?.question}
                    {activeFAQ === faqId ? (
                      <MdKeyboardArrowUp
                        size={24}
                        style={{ marginLeft: "auto" }}
                      />
                    ) : (
                      <MdKeyboardArrowDown
                        size={24}
                        style={{ marginLeft: "auto" }}
                      />
                    )}
                  </div>
                  <div className={style.faq_section_item_body}>
                    <div className={style.faq_section_item_body_content}>
                      {e?.answer}
                    </div>
                  </div>
                  {/* <!-- /.accordion-item-body --> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export const NavBar = () => {
  return (
    <div className={style.navbar}>
      <img
        src={logo}
        alt="logo"
        style={{ maxWidth: "108px", height: "20px" }}
      />
      <button
        className={style.nav_rounded_btn}
        onClick={() => {
          mixpanel.track("clicked button: For Brands - navbar");
          window.open(
            `${urlStrings.COLLAB_CLIENT}/brand?ref=homepage`,
            "_blank"
          );
        }}
      >
        <span>For Brands</span>
        <GoLinkExternal color="#ff5c5c" size={18} />
      </button>
    </div>
  );
};

export const Footer = () => {
  return (
    <div className={style.footer_container}>
      <div className={style.footer_logo_text}>anchors</div>
      <div className={style.footer_links_container}>
        <div className={style.footer_link_holder}>
          <div className={style.footer_link_title}>Quick Links</div>
          <a
            target="blank"
            href="/earning-predictor"
            onClick={() =>
              mixpanel.track("clicked link: EPA for YouTubers - footer")
            }
          >
            EPA for YouTubers
          </a>
          <a
            target="blank"
            href={`${urlStrings.COLLAB_CLIENT}/influencer`}
            onClick={() =>
              mixpanel.track(
                "clicked link: Collab for LinkedIn creators - footer"
              )
            }
          >
            Collab for LinkedIn creators
          </a>
          <a
            target="blank"
            href={`${urlStrings.COLLAB_CLIENT}/brand`}
            onClick={() => mixpanel.track("clicked link: For Brands - footer")}
          >
            For Brands
          </a>
          <a
            target="blank"
            href="/aboutUs"
            onClick={() => mixpanel.track("clicked link: About Us - footer")}
          >
            About Us
          </a>
        </div>
        <div className={style.footer_link_holder}>
          <div className={style.footer_link_title}>Events</div>
          <a
            target="blank"
            href={`${urlStrings.EVENT_CLIENT}?ref=coaches`}
            onClick={() =>
              mixpanel.track("clicked link: Event Hosting for Coach - footer")
            }
          >
            Event Hosting for Coach
          </a>
          <a
            target="blank"
            href={`${urlStrings.EVENT_CLIENT}?ref=solopreneurs`}
            onClick={() =>
              mixpanel.track(
                "clicked link: Event Hosting for Solopreneurs - footer"
              )
            }
          >
            Event Hosting for Solopreneurs
          </a>
          <a
            target="blank"
            href={`${urlStrings.EVENT_CLIENT}?ref=company`}
            onClick={() =>
              mixpanel.track(
                "clicked link: Event Hosting for the company - footer"
              )
            }
          >
            Event Hosting for the company
          </a>
          <a
            target="blank"
            href={`${urlStrings.EVENT_CLIENT}?ref=educators`}
            onClick={() =>
              mixpanel.track(
                "clicked link: Event Hosting for educators - footer"
              )
            }
          >
            Event Hosting for educators
          </a>
        </div>
        <div className={style.footer_link_holder}>
          <div className={style.footer_link_title}>Others</div>
          <a
            target="blank"
            href="/privacy-policy"
            onClick={() =>
              mixpanel.track("clicked link: Privacy policy - footer")
            }
          >
            Privacy policy
          </a>
          <a
            target="blank"
            href="/termsConditions"
            onClick={() => mixpanel.track("clicked link: T&C - footer")}
          >
            T&C
          </a>
          <a
            target="blank"
            href="/refundPolicy"
            onClick={() =>
              mixpanel.track("clicked link: Refund Policy - footer")
            }
          >
            Refund Policy
          </a>
          <a
            target="blank"
            href="/contactUs"
            onClick={() => mixpanel.track("clicked link: Contact Us - footer")}
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};
